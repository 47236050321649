@font-face {
    font-family: Climate;
    src: url(../public/fonts/ClimateCrisis-Regular.ttf);
}

@font-face {
    font-family: RubikLight;
    src: url(../public/fonts/Rubik-Light.ttf);
}

@font-face {
    font-family: RubikSemibold;
    src: url(../public/fonts/Rubik-SemiBold.ttf);
}

@font-face {
    font-family: RubikMedium;
    src: url(../public/fonts/Rubik-Medium.ttf);
}


html,
body {
    padding: 0;
    margin: 0;
    font-family: RubikLight, Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    background-color: #232323;
    color: #232323;
}

body {
    background-color: #ffffff;
}

.container {
    max-width: 1500px;
    padding: 0 10px;
    margin: 0 auto;
}

.containerText {
    max-width: 55%;
    margin: 0 auto;
}

.containerText p:last-child {
    margin-bottom: 3em;
}

.bold {
    font-family: RubikSemibold, Arial, Helvetica, sans-serif;
}

.oranzovy {
    color: #FF5F01;
}

a {
    text-decoration: none;
    line-height: normal
}

/* pro mediaqueries, pro responzivitu */
.proDesktop {
    display: inline;
}

/* header */
header {
    background-color: #232323;
    position: relative;
    z-index: 10;
}

.header-flexbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    height: 100px;
    object-fit: contain;
}

.navigation-flexbox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 66%;
}

header .navText {
    width: 16.6%;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-family: RubikSemibold, Arial, Helvetica, sans-serif;
    font-size: 1.2em;
    transition: all 0.5s;
    cursor: pointer;
    width: max-content;
    text-decoration: none;
    white-space: nowrap;
}

header .navText:hover {
    color: #FF5F01;
}

.modry {
    background-color: #6022fe;
}

.navitem {
    width: 16.6%;
    text-align: center;
    position: relative;
    z-index: 1;
    padding: 2em 0;
}

.navsubmenu {
    position: absolute;
    display: flex;
    top: 60px;
    left: -2em;
    z-index: 2;
    background-color: #232323;
    text-align: left;
    flex-direction: column;
    transition: all 0.5s;
    padding: 0.5em 0;
}

.navsubmenu .navText {
    width: auto;
    flex: 1;
    text-align: left;
    font-size: 1em;
    font-family: RubikSMedium, Arial, Helvetica, sans-serif;
    padding: 1em 2em;
}

.navsubmenu .navText a {
    padding: 0.5em 2em;
}

#registrace {
    padding: 0.5em 1em;
    background: linear-gradient(270deg, #FF5F01 19.55%, #6022FE 100%);
    border-radius: 60px;
    transition: all 0.5s;
    text-decoration: none;
    color: #ffffff;
}

#registrace:visited {
    color: #ffffff;
}

#registrace:hover {
    background: linear-gradient(270deg, #6022FE 19.55%, #6022FE 100%);
}

#registrace.hidden {
    visibility: hidden;
}

.mobile-nav-icon {
    display: none;
}

/* section */
/* spolecne */
section {
    text-align: center;
    position: relative;
    width: 100%;
    min-height: 843px;
    background-color: #ffffff;
    line-height: 0;
}

section p,
section ol {
    line-height: 1;
}

section img {
    width: 100%;
    object-fit: contain;
}

.hlavicka {
    margin: 0 auto 2em auto;
    background-color: #FF5F01;
    background-image: url(../public/images/hlavicky/hlavicka1.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 60px 0;
}

h1 {
    font-family: Climate;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 4em;
    font-weight: normal;
    letter-spacing: 2px;
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 20px 40px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
    background-repeat: no-repeat;
    background-image: repeating-linear-gradient(0deg, #ffffff, #ffffff 30px, transparent 30px, transparent 50px),
        /* left */
        repeating-linear-gradient(90deg, #ffffff, #ffffff 30px, transparent 30px, transparent 50px),
        /* top */
        repeating-linear-gradient(180deg, #ffffff, #ffffff 30px, transparent 30px, transparent 50px),
        /* right */
        repeating-linear-gradient(270deg, #ffffff, #ffffff 30px, transparent 30px, transparent 50px)
        /* bottom */
    ;
    line-height: normal;
}

h2 {
    font-family: Climate;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 2em;
    font-weight: normal;
    letter-spacing: 2px;
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 20px 40px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
    background-repeat: no-repeat;
    background-image: repeating-linear-gradient(0deg, #6022FE, #6022FE 20px, transparent 20px, transparent 30px),
        /* left */
        repeating-linear-gradient(90deg, #6022FE, #6022FE 20px, transparent 20px, transparent 30px),
        /* top */
        repeating-linear-gradient(180deg, #6022FE, #6022FE 20px, transparent 20px, transparent 30px),
        /* right */
        repeating-linear-gradient(270deg, #6022FE, #6022FE 20px, transparent 20px, transparent 30px)
        /* bottom */
    ;
    line-height: normal;
}

h3 {
    font-size: 1.8em;
    color: #FF5F01;
    text-transform: uppercase;
    font-family: RubikSemibold, Arial, Helvetica, sans-serif;
    padding: 0.5em 0;
    line-height: normal;
}

.carkovany {
    position: relative;
    width: fit-content;
    margin: 0.5em auto;
}

.ctverec {
    position: absolute;
    width: 16px;
    height: 16px;
    border: 2px solid #ffffff;
}

.levyhorni {
    top: -8px;
    left: -8px;
}

.pravyhorni {
    top: -8px;
    right: -8px;
}

.levydolni {
    bottom: -8px;
    left: -8px;
}

.pravydolni {
    right: -8px;
    bottom: -8px;
}

.horni {
    top: -8px;
    left: calc(50% - 8px);
}

.dolni {
    bottom: -8px;
    left: calc(50% - 8px);
}

.button {
    padding: 1% 2%;
    margin: 1em auto 3em auto;
    width: fit-content;
    background-color: #6022FE;
    border-radius: 60px;
}

.button a {
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-family: RubikSemibold, Arial, Helvetica, sans-serif;
    font-size: 1.2em;
}

.flexboxPorota {
    display: flex;
    justify-content: space-between;
    margin: 1em 0;
    flex-wrap: wrap;
    column-gap: 2em;
}

.flexboxKontakt {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 1em 0;
    flex-wrap: wrap;
    height: 500px;
}

.flexboxitem {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.itemPorota {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 2em 0;
}

.itemPorota p {
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
}

.fotoramecek {
    height: 150px;
    width: 150px;
    background-color: #bdbdbd;
    border: 5px solid #ffffff;
    border-radius: 50%;
}

.kontaktTexty {
    text-align: left;
    width: 250px;
    line-height: normal;
}

.kontaktTexty p {
    margin-block-start: 0;
    margin-block-end: 0;
}

.kontakt p a {
    font-family: RubikSemibold, Arial, Helvetica, sans-serif;
    color: #232323;
}

.itemKontakt {
    display: flex;
    flex-direction: row;
    gap: 2em;
}

.jmeno {
    font-family: RubikSemibold, Arial, Helvetica, sans-serif;
    color: #FF5F01;
    font-size: 1.5em;
    margin: 1em 0;
    line-height: 1;
}

.flexboxKontakt a h3,
.flexboxPorota a h3 {
    color: #232323;
}

ol {
    text-align: left;
    counter-reset: li;
    margin-left: 0;
    margin-bottom: 3em;
    padding-left: 0;
}

ol>li {
    position: relative;
    margin-top: 3.5em;
    list-style: none;
}

ol>li:before {
    content: counter(li) ".";
    counter-increment: li;
    position: absolute;
    top: -1.2em;
    font-size: 1.8em;
    color: #FF5F01;
    text-transform: uppercase;
    font-family: RubikSemibold, Arial, Helvetica, sans-serif;
}

table {
    width: auto;
    border-collapse: collapse;
    color: #FF5F01;
    margin: 0.5em auto;
    line-height: normal;
}

td {
    padding: 0.5em 2em;
    text-align: left;
    width: fit-content;
}

td:nth-child(2) {
    color: #232323;
    text-align: center;
}

.cenaKruh {
    height: 150px;
    width: 150px;
    background: linear-gradient(222deg, #FF5F01 13%, #6022FE 86%);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: normal;
}

.cena {
    color: #ffffff;
    font-size: 1.8em;
    font-family: RubikSemibold, Arial, Helvetica, sans-serif;
}

.bezDph {
    color: #ffffff;
}

.kategorie {
    display: flex;
    flex-direction: column;
    margin: 2em 0;
    transition: all 0.5s;
    cursor: pointer;
}

.kategorieHlavicka {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.kategorieHlavicka svg {
    width: 5em;
    transition: all 0.5s;
}

.kategorieHlavicka h3 {
    text-transform: none;
    text-align: left;
    margin-block-start: 0;
    margin-block-end: 0;
    color: #bdbdbd;
    transition: all 0.5s;
}

.kategoriePodtrzeni {
    width: 100%;
    border-bottom: 2px solid #bdbdbd;
    transition: all 0.5s;
}

.kategorieText {
    text-align: left;
    padding-left: 4.5em;
}

.kategorie.active .kategorieHlavicka svg {
    transform: rotate(180deg);
}

.kategorie.active .kategorieHlavicka h3 {
    color: #FF5F01;
}

.kategorie.active .kategorieHlavicka .kategoriePodtrzeni {
    border-bottom: 2px solid #6022FE;
}

.kategorie.active .kategorieHlavicka svg .st0 {
    fill: #6022FE;
}

.kategorie.active .kategorieHlavicka svg .st1 {
    fill: #FF5F01;
}

/* detailni zmeny*/
.uvod {
    background-color: #FF5F01;
}
#uvod {
    min-height: auto;
}
#hlavickaUvod {
    min-height: 850px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 0;
    z-index: 2;
    width: 100%;
    margin: auto;
}

#hlavickaUvod .carkovany {
    animation: animHlavickaUvod 4s;
}

#hlavickaUvod h3 {
    padding: 0;
    margin-top: 1.5em;
    animation: animHlavickaUvod 5s;
    color: #ffffff;
}

#hlavickaUvod .button {
    animation: animHlavickaUvod 6s;
}

.pozadiUvod {
    position: relative;
    z-index: 1;
    min-height: 850px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pozadiUvod svg {
    height: 700px;
}

.posledniobr {
    margin-bottom: 3em;
}

/* animace uvod */
@keyframes animHlavickaUvod {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.pozadiUvod svg #kruhPrechod {
    transform-box: fill-box;
    transform-origin: 50% 50%;
    animation: animKruhPrechod 2s;
}

@keyframes animKruhPrechod {
    0% {
        transform: scale(0%);
    }

    100% {
        transform: scale(100%);
    }
}

.pozadiUvod svg #kruhModry {
    transform-box: fill-box;
    transform-origin: 50% 50%;
    animation: animKruhModry 2s, animKruhModryRotace 10s infinite 2s linear;
}

@keyframes animKruhModry {
    0% {
        transform: scale(0%);
    }

    50% {
        transform: scale(0%);
    }

    100% {
        transform: scale(100%);
    }
}

@keyframes animKruhModryRotace {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.pozadiUvod svg #kruhBily {
    transform-box: fill-box;
    transform-origin: 50% 50%;
    animation: animKruhBily 2s;
    opacity: 0.5;
}

@keyframes animKruhBily {
    0% {
        transform: scale(0%);
    }

    60% {
        transform: scale(0%);
    }

    100% {
        transform: scale(100%);
    }
}

/* konec animace uvod */

.uvod .ctverec {
    border: 2px solid #6022FE;
}

.uvod .horni,
.uvod .dolni {
    border: 2px solid #FF5F01;
}

#hlavickaHistorie,
#hlavickaSocky {
    background-image: url(../public/images/hlavicky/hlavicka2.svg);
    margin-top: 4em;
}

#hlavickaPostup,
#hlavickaPoplatky {
    background-image: url(../public/images/hlavicky/hlavicka3.svg);
    margin-top: 4em;
}

/* footer */
footer {
    background-color: #232323;
    height: 100px;
    color: #ffffff;
}

.footer-flexbox {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

footer a img {
    max-width: 125px;
    max-height: 40px;
}

.footer-social {
    width: 15%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.footer-social img {
    width: 100%;
}

.footer-partners {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.none {
    display: none;
}

.flexboxVysledky {
    display: flex;
    justify-content: center;
    gap: 3%;

}

.vysledkyItem {
    width: 30%;
    background: linear-gradient(0deg, #FF5F01 19.55%, #6022FE 100%);
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vysledkyItem:nth-child(even) {
    background: linear-gradient(180deg, #FF5F01 19.55%, #6022FE 100%);

}

.vysledkyItemText {
    font-family: RubikSemibold, Arial, Helvetica, sans-serif;
    color: #ffffff;
    font-size: 1.5em;
    padding: 0.5em 2em;
    background-color: #FF5F01;
    border-radius: 60px;
}

.bezTitulek {
    margin: 2em;
}

.vysledkyRocnik {
    font-family: RubikSemibold, Arial, Helvetica, sans-serif;
    color: #ffffff;
    font-size: 1.5em;
    padding: 1em 3em;
    background: linear-gradient(90deg, #FF5F01 19.55%, #6022FE 100%);
    border-radius: 60px;
    text-decoration: none;
}

.vysledky table {
    border-radius: 30px;
    overflow: hidden;
    margin: 2em auto;
    width: 100%;
    box-shadow: #a5a5a5 5px 5px 5px;
}

.vysledky table tr td {
    text-align: center;
    color: #232323;
    padding: 1em;
}

.vysledky table tr {
    background-color: #f6f6f6;
}

.vysledky .vysledkySpecial {
    background-color: #f6f6f6;
    width: 30%;
}

.vysledky table tr:nth-child(even) {
    background-color: #ffffff
}

.vysledky table tr:first-child {
    background-color: #6022FE;
}

.vysledky .vysledkySpecial tr:first-child {
    background: linear-gradient(90deg, #FF5F01 19.55%, #6022FE 100%);
}

.vysledky table tr:first-child td {
    font-family: RubikSemibold, Arial, Helvetica, sans-serif;
    color: #ffffff;
    text-transform: uppercase;
}

.vysledky table tr.seda {
    background-color: #a5a5a5;
}

/* pro mediaqueries, pro responzivitu */
.proMobil {
    display: none;
}

/* media queries */
@media only screen and (max-width: 1400px) {
    .containerText {
        max-width: 65%;
    }

}

@media only screen and (max-width: 1300px) {
    .container {
        padding: 0;
    }

    .containerText {
        max-width: 75%;
    }

    section,
    #hlavickaUvod,
    .pozadiUvod {
        min-height: 700px;
    }

    .pozadiUvod svg {
        height: 650px;
    }
    .footer-partners {
        width: 65%;
    }
}

@media only screen and (max-width: 1100px) {
    h1 {
        font-size: 3em;
    }

    section,
    #hlavickaUvod,
    .pozadiUvod {
        min-height: 600px;
    }

    .pozadiUvod svg {
        height: 600px;
    }

}

@media only screen and (max-width: 950px) {
    .footer-flexbox {
        flex-direction: column;
    }
    .footer-partners {
        width: 80%;
    }
    .footer-social {
        width: 100%;
        justify-content: center;
        gap: 2em;
    }
}

@media only screen and (max-width: 800px) {
    .containerText {
        max-width: 85%;
    }

    .proDesktop {
        display: none;
    }

    .proMobil {
        display: block;
    }

    header {
        position: fixed;
        top: 0;
        width: 100%;
    }

    .uvod {
        min-height: 600px;
        /* margin-top: 90px; */
    }

    section {
        min-height: auto;
    }

    .mobile-nav-icon {
        display: block;
        height: 2em;
        padding: 0 1em;
        align-self: center;
        z-index: 3;
    }

    #mainMenu {
        display: none;
        width: 100%;
        padding-top: 90px;
        padding-bottom: 2em;
    }

    #mainMenu.otevrene {
        display: flex;
    }

    .navigation-flexbox {
        flex-direction: column;
        position: absolute;
        right: 0;
        width: max-content;
        background-color: #232323;
    }

    .header-flexbox {
        align-items: flex-start;
    }

    h1 {
        font-size: 2.5em;
    }

    h2 {
        font-size: 1.5em;
        background-image: repeating-linear-gradient(0deg, #ffffff, #ffffff 20px, transparent 20px, transparent 30px),
            /* left */
            repeating-linear-gradient(90deg, #ffffff, #ffffff 20px, transparent 20px, transparent 30px),
            /* top */
            repeating-linear-gradient(180deg, #ffffff, #ffffff 20px, transparent 20px, transparent 30px),
            /* right */
            repeating-linear-gradient(270deg, #ffffff, #ffffff 20px, transparent 20px, transparent 30px)
            /* bottom */
        ;
    }

    .carkovany {
        width: calc(100% - 2em);
    }

    .uvod .carkovany {
        width: fit-content;
    }

    .hlavicka,
    #hlavickaPostup,
    #hlavickaPoplatky {
        padding: 40px 0;
        background-image: url(../public/images/hlavicky/hlavickaMobil.svg);
        margin-top: 0;
    }

    .proNavigaci {
        height: 4em;
    }

    #hlavickaUvod h3 {
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
    }

    header .navText {
        font-size: 1em;
        text-align: center;
        font-family: RubikLight;
    }

    header .navText a {
        color: #ffffff;
        display: block;
        padding: 1em;
    }

    header .navText.active {
        color: #FF5F01;
        font-family: RubikMedium;
    }

    .logo {
        height: 70px;
    }

    .ctverec {
        width: 12px;
        height: 12px;
        border: 2px solid #6022FE;
    }

    .horni,
    .dolni {
        border: 2px solid #FF5F01
    }

    section img {
        width: 320px;
        margin: 0 auto;
    }
    #hlavickaUvodFoto img{
        width: 100%;
    }

    .kategorieHlavicka h3 {
        text-align: center;
    }

    .kategorieHlavicka svg {
        display: none;
    }

    .kategorieText {
        text-align: center;
        padding-left: 0;
    }

    .button {
        margin: 1em auto;
        padding: 3% 5%;
    }

    .proNavigaciTerminy {
        background-color: #232323;
    }

    .footer-partners {
        font-size: 0.8em;
        flex-direction: column;
        gap: 3em;
        margin-bottom: 5em;
    }

    .upoutavka {
        padding-top: 68px;
        background-color: #232323;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .upoutavkaItem {
        padding: 0.5em 0;
    }

    .upoutavka h3 {
        color: #ffffff;
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
    }

    .upoutavka p {
        color: #FF5F01;
        font-size: 1.8em;
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
    }

    .upoutavka .button {
        background-color: #FF5F01;
    }
}

@media only screen and (max-width: 650px) {
    .logo {
        height: 40px;
    }

    /* .uvod {
        margin-top: 76px;
    } */

    #mainMenu {
        padding-top: 76px;
    }

    .kontaktTexty {
        width: 200px;
    }
}

@media only screen and (max-width: 600px) {
    .itemPorota {
        width: 45%;
    }

}

@media only screen and (max-width: 550px) {

    h1 {
        font-size: 2em;
        background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
    }

    h2 {
        background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
    }

    .ctverec {
        border: 1px solid #6022FE;
    }

    .horni,
    .dolni {
        border: 1px solid #FF5F01;
    }

    h3 {
        font-size: 1.2em;
    }

    .button a {
        font-size: 1em;
    }

    section,
    .uvod,
    #hlavickaUvod,
    .pozadiUvod {
        min-height: 400px;
    }

    #hlavickaUvod,
    .pozadiUvod svg {
        height: 500px;
    }

    td {
        padding: 0.25em 0.1em;
    }
}

@media only screen and (max-width: 500px) {

    #hlavickaUvod,
    .pozadiUvod {
        overflow: hidden;
    }
}

@media only screen and (max-width: 450px) {

    section,
    .pozadiUvod {
        min-height: 300px;
    }

    /* .uvod {
        margin-top: 62px;
    } */

    #mainMenu {
        padding-top: 62px;
    }

    #hlavickaUvod,
    .pozadiUvod svg {
        height: 400px;
    }

    h1 {
        padding: 15px;
        font-size: 1.56em;
    }

    h3 {
        font-size: 1em;
    }

    .button a {
        font-size: 0.8em;
    }

    header .navText {
        font-size: 0.8em;
    }

    .itemPorota {
        width: 100%;
    }

    .flexboxKontakt {
        height: auto;
        gap: 1em;
        margin-bottom: 2em;
    }

    .itemKontakt {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 0;
    }

    .kontaktTexty {
        text-align: center;
    }

    section img {
        width: auto;
        margin: 0 auto;
    }
}